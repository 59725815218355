// extracted by mini-css-extract-plugin
export var desktop_mobile_h3 = "styles-module--desktop_mobile_h3--faf0d";
export var planet = "styles-module--planet--db5ec";
export var planet__content__first = "styles-module--planet__content__first--dddbe";
export var planet__content__first__collapsible = "styles-module--planet__content__first__collapsible--edaac";
export var planet__content__first__impact = "styles-module--planet__content__first__impact--a3321";
export var planet__content__fourth = "styles-module--planet__content__fourth--89f36";
export var planet__content__fourth__links = "styles-module--planet__content__fourth__links--180a4";
export var planet__content__fourth__links__top = "styles-module--planet__content__fourth__links__top--7cd15";
export var planet__content__second = "styles-module--planet__content__second--37a2d";
export var planet__content__second__infographic = "styles-module--planet__content__second__infographic--688d0";
export var planet__content__second__link = "styles-module--planet__content__second__link--92fe7";
export var planet__content__second__links = "styles-module--planet__content__second__links--52e85";
export var planet__content__second__links__climate_logo = "styles-module--planet__content__second__links__climate_logo--a970a";
export var planet__content__second__links__sbt_logo = "styles-module--planet__content__second__links__sbt_logo--01c0f";
export var planet__content__second__links__top = "styles-module--planet__content__second__links__top--a5618";
export var planet__content__second__method__climate_logo = "styles-module--planet__content__second__method__climate_logo--d0a67";
export var planet__content__third = "styles-module--planet__content__third--56974";
export var planet__content__third__infographic = "styles-module--planet__content__third__infographic--7fddc";
export var waterStewardshipImages = "styles-module--water-stewardship-images--28daf";
export var waterStewardshipImages__image = "styles-module--water-stewardship-images__image--249fe";