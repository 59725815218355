import { Helmet } from "react-helmet";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import AboutLink from "./components/AboutLink";
import BannerSection from "./components/BannerSection";
import Collapsible from "./components/Collapsible";
import ExploreMore from "./components/ExploreMore";
import SectionTitle from "./components/SectionTitle";
import ScrollMenu from "./components/ScrollMenu";
import SideMenu from "./components/SideMenu";
import SEO from "../../components/seo";

// Images
import firstImgD from "../../images/about_images/Our Planet Page/Desktop/OurPlanet_hero_desktop.jpg";
import secondImgD from "../../images/about_images/Our Planet Page/Desktop/OurPlanet_tile2_desktop.jpg";
import thirdImgD from "../../images/about_images/Our Planet Page/Desktop/OurPlanet_tile3_desktop.jpg";
import fourthImgD from "../../images/about_images/Our Planet Page/Desktop/OurPlanet_tile4_desktop.jpg";
import fifthImgD from "../../images/about_images/Our Planet Page/Desktop/OurPlanet_tile5_desktop.jpg";
import sixthImgD from "../../images/about_images/Our Planet Page/Desktop/OurPlanet_tile6_desktop.jpg";

import firstImgM from "../../images/about_images/Our Planet Page/Mobile/OurPlanet_hero_mobile.jpg";
import secondImgM from "../../images/about_images/Our Planet Page/Mobile/OurPlanet_tile2_mobile.jpg";
import thirdImgM from "../../images/about_images/Our Planet Page/Mobile/OurPlanet_tile3_mobile.jpg";
import fourthImgM from "../../images/about_images/Our Planet Page/Mobile/OurPlanet_tile4_mobile.jpg";
import fifthImgM from "../../images/about_images/Our Planet Page/Mobile/OurPlanet_tile5_mobile.jpg";
import sixthImgM from "../../images/about_images/Our Planet Page/Mobile/OurPlanet_tile6_mobile.jpg";

import climateNeutralLogo from "../../images/about_images/Our Planet Page/ClimateNeutralCertified_Label_Horizontal_BlackOutline.png";
import sbtiLogo from "../../images/about_images/Our Planet Page/SBT.png";

import * as Styles from "./ourPlanet/styles.module.css";

const menuItems = [
  {
    title: "Our Story",
    url: "/pages/our-journey",
    isActive: false,
  },
  {
    title: "Our Jewelry",
    url: "/pages/our-jewelry",
    isActive: false,
  },
  {
    title: "Our People",
    url: "/pages/our-people",
    isActive: false,
  },
  {
    title: "Our Planet",
    url: "/pages/our-planet",
    isActive: true,
    subMenuItems: [
      {
        title: "Carbon Footprint",
        url: "#carbon-footprint",
      },
      {
        title: "Water Stewardship",
        url: "#water-stewardship",
      },
      {
        title: "Circularity",
        url: "#circularity",
      },
    ],
  },
];

const methodCopy = (
  <p className="al_p">
    In 2022, we moved away from a Life Cycle Assessment and partnered with South Pole to develop our
    first Greenhouse Gas (GHG) inventory. A GHG inventory is a summation of all greenhouse gas
    emissions from an organization across its value chain. GHG inventories are typically conducted
    to support company-wide reporting, set GHG emissions reductions targets for entire
    organizations, and track progress towards meeting targets.
  </p>
);
const carbonCopy = (
  <>
    <p className="al_p">
      In 2020, we became carbon neutral, counterbalancing the entirety of our CO2 footprint for a
      net zero output, so every piece of your collection is carbon invisible. From raw material
      sourcing to end-of-life, 100% of the emissions related to the crafting of every piece of our
      jewelry is offset.
    </p>
    <br />
    <p className="al_p">
      In 2022, we joined the Science Based Targets Initiative, setting two science-based targets to
      reduce GHG emissions in line with climate science. Science based targets provide a
      clearly-defined pathway for companies and financial institutions to reduce greenhouse gas
      (GHG) emissions, helping prevent the worst impacts of climate change and future-proof business
      growth.
    </p>
    <br />
    <p className="al_p">
      The SBTi has approved our near-term science-based emissions reduction target. We have
      committed to:
      <ul>
        <li>Reduce our scope 1 + 2 emissions by 42% by 2030</li>
        <li>Reduce our scope 1, 2, + 3 emissions by 90% by 2050</li>
      </ul>
    </p>
  </>
);
const climateCopy = (
  <p className="al_p">
    We're proud to have been Climate Neutral Certified since 2021. Climate Neutral's seal of
    approval indicates that we’re taking responsibility for the carbon emissions of our entire
    supply chain. Their rigorous certification process tracks yearly carbon footprints, sets
    reduction targets, and helps offset carbon usage. As our manufacturing and materials make up the
    majority of our carbon footprint, we continue to challenge ourselves to utilize innovative,
    responsible materials to ensure environmental responsibility.
  </p>
);

function OurPlanet({ path }) {
  const methodCollapsible = (
    <div className={Styles.planet__content__second__links}>
      <Collapsible title="Methodology" className={Styles.planet__content__second__links__top}>
        {methodCopy}
      </Collapsible>
      <Collapsible title="Carbon Neutrality" className={Styles.planet__content__second__links__top}>
        {carbonCopy}
        <img
          src={sbtiLogo}
          className={Styles.planet__content__second__links__sbt_logo}
          alt="SBTi logo"
        />
      </Collapsible>
      <Collapsible title="Climate Neutral">
        <img
          src={climateNeutralLogo}
          className={Styles.planet__content__second__links__climate_logo}
          alt="Climate Neutral logo"
        />
        {climateCopy}
      </Collapsible>
    </div>
  );
  const methodExpanded = (
    <>
      <h3 className={Styles.desktop_mobile_h3}>Methodology</h3>
      {methodCopy}
      <h3 className={Styles.desktop_mobile_h3}>Carbon Neutrality</h3>
      {carbonCopy}
      <img
        src={sbtiLogo}
        className={Styles.planet__content__second__links__sbt_logo}
        alt="SBTi logo"
      />
      <h3 className={Styles.desktop_mobile_h3}>Climate Neutral</h3>
      {climateCopy}
      <img
        src={climateNeutralLogo}
        className={Styles.planet__content__second__method__climate_logo}
        alt="Climate Neutral logo"
      />
    </>
  );
  const methodContent = useResponsiveWithHydrationFix(methodCollapsible, methodExpanded);
  const fifthImage = useResponsiveWithHydrationFix(fifthImgM, fifthImgD);
  const sixthImage = useResponsiveWithHydrationFix(sixthImgM, sixthImgD);

  return (
    <div className={Styles.planet}>
      <SEO
        title="Our Planet"
        description="Discover Ana Luisa's commitment to preserving the planet through responsible craftsmanship. Explore our sustainable jewelry collection at analuisa.com and join us in making a positive impact on the environment."
      />
      <Helmet>
        <link rel="canonical" href={`https://www.analuisa.com${path}`} />
        <meta property="og:url" content={`https://www.analuisa.com${path}`} />
      </Helmet>
      <ScrollMenu active="planet" />
      <SideMenu menuItems={menuItems} />
      <div>
        <BannerSection
          className={Styles.planet__content__first}
          imageSrc={{ desktop: firstImgD, mobile: firstImgM }}
          imageAlt="Ana Luisa Jewelry Carbon Footprint"
          landmark="carbon-footprint"
        >
          <SectionTitle title="Crafted with the Planet in Mind" isTitle />
          <p className="al_p">
            In 2022, we conducted our first greenhouse gas (GHG) inventory. This was a massive step
            for us in better understanding our environmental impact and how to further minimize our
            carbon footprint through certified efforts.
          </p>
          <br />
          <p>
            We’ve tracked and analyzed our greenhouse gas emissions throughout every phase of
            sourcing and production in line with climate science. We then used the results to
            evaluate our process which helped us earn our Climate Neutral Certification for the
            second year in a row.{" "}
          </p>
          <br />
          <p>
            We know we have a long way to go though we’re committed to the journey ahead. We believe
            that through meaningful action, consistent accountability, and science-backed efforts,
            we can achieve a brighter future, together.
          </p>
        </BannerSection>
        <BannerSection
          className={Styles.planet__content__second}
          imageSrc={{ desktop: secondImgD, mobile: secondImgM }}
          imageAlt="Ana Luisa Jewelry Carbon"
          landmark="Carbon"
        >
          {methodContent}
        </BannerSection>
        <BannerSection
          className={Styles.planet__content__third}
          imageSrc={{ desktop: thirdImgD, mobile: thirdImgM }}
          imageAlt="Ana Luisa Jewelry Water"
          landmark="water-stewardship"
        >
          <SectionTitle title="Water Stewardship" />
          <div className={Styles.waterStewardshipImages}>
            <img src={fifthImage} className={Styles.waterStewardshipImages__image} alt="Oysters" />
            <img src={sixthImage} className={Styles.waterStewardshipImages__image} alt="People" />
          </div>
          <p className="al_p">
            The jewelry industry as a whole is water intensive and has significant impacts on water
            supply. At Ana Luisa, we strive to be a part of the solution and recognize and address
            our impact, particularly at the source of our production. In 2022, we joined forces with
            A Plastic Ocean Foundation to do just that.
          </p>
          <br />
          <p className="al_p">
            A Plastic Ocean Foundation (APO) is a Hong Kong-based water advocacy organization
            focused on climate resilience, water usage education, and water pollution reduction
            along the Hong Kong coastline. Ana Luisa has partnered with APO on their Sustainable
            Pearl Cultivation and Pearl Adoption project. Since 2019, APO has been working on
            sustainably cultivating pearl oysters with goals to rejuvenate the seawater and conserve
            biodiversity.
          </p>
          <br />
          <p className="al_p">
            Through our partnership, we adopted 97 oysters along Hong Kong coastal waters. Each
            oyster filters up to 33,000 liters of ocean water over 6 months, equivalent to the size
            of two 25m swimming pools!
          </p>
        </BannerSection>
        <BannerSection
          className={Styles.planet__content__fourth}
          imageSrc={{ desktop: fourthImgD, mobile: fourthImgM }}
          imageAlt="Ana Luisa Jewelry Necklaces Rings Bracelets"
          landmark="circularity"
        >
          <SectionTitle title="Our Approach to Circularity" />
          <p className="al_p">
            Circularity is critical as we continue on our sustainability journey as we ensure our
            products are created with end-use in mind. Ideally, at the end of our jewelry’s life, it
            will be recycled or reused rather than end up in a landfill.
          </p>
          <br />
          <p className="al_p">
            We know that being a responsible jewelry brand doesn’t stop at checkout. It’s our goal
            to ensure our pieces are treated responsibly, from design to end of life.
          </p>
          <br />
          <p className="al_p">
            Since 2021, Ana Luisa has donated over 13,000 preloved pieces of jewelry. We also
            currently partner with the Princess Project, an organization that provides free prom
            dresses and accessories to high school teens who otherwise might not be able to afford
            them. Our efforts have saved approximately 12 tons of CO2e and 71,400 gallons of water.
            Sometimes, it’s the space you don’t take up that makes a difference.
          </p>
          <br />
          <p className="al_p">
            We hope to achieve an even more sustainable and circular process in the future. We have
            our eyes set on innovative forms of recycled materials, crafting our own recycled brass
            alloy, and we continue to research potential recycling partners to create an even more
            circular process.
          </p>
          <div className={Styles.planet__content__fourth__links}>
            <AboutLink
              to="/collections/bestsellers"
              className={Styles.planet__content__fourth__links__top}
            >
              Shop our best sellers
            </AboutLink>
            <AboutLink to="/collections/new" className={Styles.planet__content__fourth__links__top}>
              Shop our new arrivals
            </AboutLink>
          </div>
        </BannerSection>
        <ExploreMore current="planet" />
      </div>
    </div>
  );
}

export default OurPlanet;
